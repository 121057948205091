<template>
  <Layout>
    <div class="container">
      <div id="resetForm" class="container is-fluid">
        <h1>Create your new password</h1>
        <div class="columns">
          <div class="column">
            <form
              id="registrationForm"
              class="mainFormContainer"
              @submit="submitForm($event)"
              novalidate
            >
              <div class="fieldContainer">
                <div class="field">
                  <label class="label" for="password">
                    Password
                    <span
                      class="icon right tooltip is-tooltip-left is-tooltip-multiline"
                      data-tooltip="The password must be between 8 and 15 characters and contain lower case, cappital letters, numbers and #, @ or * "
                    >
                      <icon :icon="['fas', 'question-circle']" />
                    </span>
                  </label>
                  <div class="control has-icons-right">
                    <input
                      id="password"
                      name="password"
                      class="input"
                      :class="{ 'is-danger': !password.valid }"
                      type="password"
                      v-model="newPassword"
                      placeholder="Password"
                      required="required"
                      :pattern="passwordPattern"
                      @keyup="emitChange($event)"
                    />
                    <span class="icon is-medium is-right">
                      <icon :icon="['fas', 'lock']" />
                    </span>
                  </div>
                  <p class="help" :class="{ 'is-danger': !password.valid }">
                    {{ password.errorMessage }}
                  </p>
                </div>
              </div>
              <div class="fieldContainer">
                <div class="field">
                  <label class="label" for="confirmPassword"
                    >Confirm Password</label
                  >
                  <div class="control">
                    <input
                      id="confirmPassword"
                      name="confirmPassword"
                      class="input"
                      :class="{ 'is-danger': !confirmPassword.valid }"
                      type="password"
                      v-model="confirmNewPassword"
                      placeholder="Confirm Password"
                      required="required"
                      :pattern="passwordPattern"
                      @keyup="emitChange($event)"
                    />
                  </div>
                  <p
                    class="help"
                    :class="{ 'is-danger': !confirmPassword.valid }"
                  >
                    {{ confirmPassword.errorMessage }}
                  </p>
                </div>
              </div>
              <button
                type="submit"
                class="button is-fullwidth is-primary text-capitalize"
              >
                Save
              </button>
            </form>
          </div>
          <div class="column">
            <label class="label">Tips for a secure password</label>
            <ul>
              <li>Don't use a password you have used on form previously.</li>
              <li>Must use at least one letter.</li>
              <li>Must use at lear one number.</li>
              <li>Don't use the same password for multiple online accounts.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" :class="{ 'is-active': modal.visible }">
      <div class="modal-background"></div>
      <div class="modal-card">
        <section class="modal-card-body">
          <h1>{{ modal.header }}</h1>
          <label class="label">{{ modal.body }}</label>
        </section>
        <footer class="modal-card-foot">
          <button
            @click="modalEvt()"
            class="button is-fullwidth is-primary text-capitalize"
          >
            {{ modal.button }}
          </button>
        </footer>
      </div>
    </div>
  </Layout>
</template>

<script>
import axios from 'axios';

export default {
  name: 'resetPassword',
  components: {
    Layout: () => import('@/router/layouts/Main')
  },
  props: {},
  data() {
    return {
      passwordPattern: '^[a-zA-Z0-9@*#]{8,15}$',
      modal: {
        visible: false,
        header: '',
        body: '',
        button: '',
        login: false
      },
      newPassword: '',
      confirmNewPassword: '',
      password: {
        valid: true,
        errorMessage: ''
      },
      confirmPassword: {
        valid: true,
        errorMessage: ''
      }
    };
  },
  methods: {
    modalEvt() {
      if (this.modal.login) {
        this.$router.push('/login');
      } else {
        this.modal.visible = false;
        this.modal.header = '';
        this.modal.body = '';
        this.button = '';
      }
    },
    async submitForm(evt) {
      evt.preventDefault();
      let form = this.$el.querySelector('#registrationForm');
      let fields = [
        {
          field: this.$el.querySelector('#password'),
          validation: this.password
        },
        {
          field: this.$el.querySelector('#confirmPassword'),
          validation: this.confirmPassword
        }
      ];
      if (fields[0].field.value !== fields[1].field.value) {
        form.valid = false;
        fields[1].field.valid = false;
        fields[1].validation.valid = false;
        fields[1].validation.errorMessage = 'Password fields must match';
        return;
      } else {
        form.valid = true;
        fields[1].field.valid = true;
        fields[1].validation.valid = true;
        fields[1].validation.errorMessage = '';
      }
      if (form.checkValidity()) {
        try {
          const response = await axios.post(
            $formsConfig.core.api.reset + this.$route.params.token,
            {
              password: fields[0].field.value
            }
          );
          this.modal.visible = true;
          this.modal.header = 'Great! Your new password has been saved.';
          this.modal.body =
            'You can log in to Forms with your email address and your new password.';
          this.modal.button = 'Return to login';
          this.modal.login = true;
        } catch (error) {
          this.modal.visible = true;
          this.modal.header = 'There was an error.';
          this.modal.body = 'Token has expired or is not valid.';
          this.modal.button = 'Cancel';
        }
      } else {
        // If the form is invalid, iterate over the fields to mark the ones
        // that need attention.
        fields.forEach(function(fieldObject) {
          fieldObject.validation.valid = fieldObject.field.checkValidity();
          fieldObject.validation.errorMessage =
            fieldObject.field.validationMessage;
        });
      }
    },
    validate(fieldObject) {
      let valid = fieldObject.field.checkValidity();
      fieldObject.validation.valid = valid;
      fieldObject.validation.errorMessage = fieldObject.field.validationMessage;
      return valid;
    },
    clearValidity(fieldObject) {
      fieldObject.validation.valid = true;
      fieldObject.validation.errorMessage = '';
    },
    emitChange(evt) {
      let field = evt.currentTarget;
      let fieldObject = {
        field: field,
        validation: this[field.attributes.name.value]
      };
      if (!this.validate(fieldObject)) {
        return;
      }
      this.clearValidity(fieldObject);
    }
  }
};
</script>

<style lang="scss" scoped>
.fieldContainer {
  @extend %fieldContainer;
}

.is-fluid {
  align-items: center;
  background: $background-panels;
  border-radius: $control-radius;
  h1 {
    margin-bottom: 30px;
    text-align: center;
  }
}

.modal-card-body {
  h1 {
    text-align: center;
  }
  .label {
    text-align: center;
  }
}

#resetForm {
  padding: 50px 50px 50px 50px;
  margin: 50px 98px 0 92px;
  .label {
    font-weight: bold;
    text-align: center;
  }
}

ul {
  li {
    margin: 15px;
    font-size: 14px;
    font-weight: normal;
  }
}
</style>
